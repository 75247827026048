/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Invite } from './components/Invite'
import { SignUp } from './components/SignUp'
import { ResetPassword } from './components/ResetPassword'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/auth' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-90px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-5 p-lg-5 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='https://www.petresortpro.com/about-us/' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='https://www.petresortpro.com/contact-us/' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='https://www.petresortpro.com/privacy/' className='text-muted text-hover-primary px-2'>
          Privacy
          </a>
        </div>
      </div>

      <div className='d-flex flex-center flex-column-auto p-10'>
        
      </div>
      {/* end::Footer */}
    </div>
  )
}
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='invite/:clientId' element={<Invite />} />
      <Route path='signup/:clientId' element={<SignUp />} />
      <Route path='resetpassword/:token' element={<ResetPassword />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)
export {AuthPage}
