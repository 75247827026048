import axios, { AxiosRequestConfig } from 'axios'
import {AuthModel} from '../models/AuthModel'
import { LoginModel } from '../models/LoginModel'
import {UserModel} from '../models/UserModel'
import { DashboardOverviewModel } from '../../dashboard/models/DashboardOverviewModel'
import {ClientProfileModel} from '../../accounts/models/ClientProfileModel'
const API_URL =  process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/Login`
export const RESET_PASSWORD_URL = `${API_URL}/ClientProfile/ResetPassword`
export const CREATE_URL = `${API_URL}/User`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/ClientProfile/ForgotPassword`
export const DASHBOARD_URL = `${API_URL}/Dashboard`
export const GET_CLIENT_PROFILE_URL=`${API_URL}/ClientProfile`
export const GET_CLIENT_INVITATION = `${API_URL}/Client`
// Server should return AuthModel
export function login(UserName: string, password: string) {
  return axios.post<LoginModel>(LOGIN_URL, {
    UserName,
    password,
  })
}

export function resetpassword(token: string, password: string) {
  return axios.post<any>(RESET_PASSWORD_URL, {
    token,
    password,
  })
}

export function create(username: string, password: string, token: string, clientId: string) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };
  const axiosHeader: AxiosRequestConfig = {headers};
  return axios.post<AuthModel>(CREATE_URL, {
    username,
    password,
    clientId
  }, axiosHeader)
}


// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
      email: email
  })
}

export async function getUserByToken(clientId: string) {
  var initUser: UserModel = {Id: '', FirstName: '', LastName: '', Email: '', username: ''};
  if(!clientId || clientId === '') return initUser;
  var res = null
  try{
    res = await  axios.get(`${DASHBOARD_URL}/${clientId}`)
  }catch(e){
    console.log(e)
  }
  var user: UserModel={Id: res?.data?.Client?.Id, FirstName:res?.data?.Client?.FirstName, LastName:res?.data?.Client?.LastName, 
    username:res?.data?.Client?.Email, Email:res?.data?.Client?.Email};  
  return user;
}


// Server should return dashboard
export async function getUserDashboardById(data: any) {
  var res = null
  var dashboard: DashboardOverviewModel={
    Id:data?.Id,
    ClientId:data?.ClientId,
    FirstName:data?.FirstName,
    LastName:data?.LastName,
    Token:data?.Token,
    Email:data?.Email,
    GuestDetails:[],
    FacilityDetails: undefined,
    ClientDetails:undefined,
    UserName: ''
  };
  var clientId = data.ClientId ? data.ClientId : data.Id;
  try{
     
        res = await  axios.get(`${DASHBOARD_URL}/${clientId}`)
        dashboard={
          Id:res.data.Id,
          ClientId:res.data.ClientId,
          FirstName:res.data.Client?.FirstName,
          LastName:res.data.Client?.LastName,
          Token:'',
          Email:res.data.Client?.Email,
          ClientDetails:res.data.Client,
          GuestDetails: res.data.Guests,
          FacilityDetails: res.data.Facility,
          Permissions: res.data.Permissions,
          UserName: res.data.UserName
        };
return dashboard;

}catch(e){
    console.log(e)
    return dashboard;

  }

}

export async function getClientInvitation(id:string) {
  var res = null
  var dashboard: DashboardOverviewModel={
    Id:1,
    ClientId:1,
    FirstName:'',
    LastName:'',
    Token:'',
    Email:'',
    GuestDetails:[],
    FacilityDetails: undefined,
    ClientDetails:undefined,
    UserName: ''
  };
  try{
        res = await  axios.get(GET_CLIENT_INVITATION+"/"+id)
        dashboard={
          Id:1,
          ClientId:1,
          FirstName:res.data.Client.FirstName,
          LastName:res.data.Client.LastName,
          Token:res.data.Token,
          Email:res.data.Client.Email,
          ClientDetails:res.data.Client,
          GuestDetails: res.data.Guests,
          FacilityDetails: res.data.Facility,
          UserName: res.data.UserName
        };
return dashboard;

}catch(e: any){
    console.log(e)
    dashboard.Error = e?.response?.data?.Message;
    return dashboard;

  }

}


export function getClientProfile(clientId:string) {
  
  var client : any  = null;
  axios.get<ClientProfileModel>(GET_CLIENT_PROFILE_URL+'/'+clientId).then(data => {
    client = data.data;
  }).catch(ex=>{
    console.log(ex);
  })


return client;

}