/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetpassword} from '../redux/AuthCRUD'

const schema = Yup.object().shape({
  Password: Yup.string()
  .min(8, 'Minimum 8 symbols')
  .max(20, 'Maximum 50 symbols')
  .required('Password is required'),
  ConfirmPassword: Yup.string()
  .min(8, 'Minimum 8 symbols')
  .max(20, 'Maximum 50 symbols')
  .required('Password Again is required'),
})

const initialValues = {
  Password: '',
  ConfirmPassword: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
  const {token} = useParams()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if(values.Password !== values.ConfirmPassword) {
        setHasErrors(true)
        setErrorMessage('Paswords does not match');
      } else {
        setErrorMessage(undefined);
        setLoading(true)
        setTimeout(() => {
          if(token) {
            resetpassword(token, values.Password)
            .then(async ({data}) => {
              console.log(data)
              setLoading(false)
              setHasErrors(false)
              window.location.href = "/auth"
            })
            .catch((err) => {
              console.log('err', err)
              setErrorMessage(err?.response?.data?.Message)
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus('The login detail is incorrect')
            })
          }
        }, 1000)
        }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
        
      </div>
      {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {errorMessage ? errorMessage : "Sorry, looks like there are some errors detected, please try again." }
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Password reset successfully</div>
          </div>
        )}

      <p className='text-gray-400 fw-bold'>Set a password. It should be 8-20 characters long and includes numbers, captial and small letters.</p>
<br></br>   
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('Password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.Password && formik.errors.Password,
            },
            {
              'is-valid': formik.touched.Password && !formik.errors.Password,
            }
          )}
        />
        {formik.touched.Password && formik.errors.Password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.Password}</span>
            </div>
          </div>
        )}
        </div>
    
      <div className='fv-row mb-10'>          
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password Again</label>
            {/* end::Label */}
            
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('ConfirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.ConfirmPassword && formik.errors.ConfirmPassword,
            },
            {
              'is-valid': formik.touched.ConfirmPassword && !formik.errors.ConfirmPassword,
            }
          )}
        />
        {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.ConfirmPassword}</span>
            </div>
          </div>
        )}
      </div>
    
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          // disabled={formik.isSubmitting || !formik.isValid}
          // onClick={props.updateInvite}

        >
          {!loading && <span className='indicator-label'>Reset</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>


      </div>
      {/* end::Action */}
    </form>
  )
}
