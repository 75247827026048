import {FC, useState, useEffect} from 'react'
import Flatpickr from "react-flatpickr"
import Moment from "moment";
import * as auth from '../../modules/auth/redux/AuthRedux';
import {connect} from 'react-redux'
import {RootState} from '../../../setup'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

var _ = require('lodash');
const API_URL =  process.env.REACT_APP_API_URL

const mapState = (state: RootState) => ({
  FirstName: state.auth?.dashboard?.FirstName,
  GuestDetails: state.auth.dashboard?.GuestDetails,
  FacilityDetails: state.auth.dashboard?.FacilityDetails,
  Permissions: state.auth.dashboard?.Permissions,
  clientId: state.auth?.clientUser?.ClientId ? state.auth?.clientUser?.ClientId : '',
  clientUser: state.auth?.clientUser,
})
const connector = connect(mapState)

const NewReservation: FC = (props: any) => {
  const date = new Date()
  const [loading, setLoading] = useState(false)
  const [reservationType, setReservationtype] = useState('')
  const [disableCheckout, setCheckoutState] = useState(false)
  const [arrdate, setArrDate] = useState(date)
  const [depdate, setDepDate] = useState(date)
  const [guestIds, setGuestIds] = useState<any[]>([])
  const [reservation, setReservation] = useState('')
  const [grooming, setGrooming] = useState('')
  const [training, setTraining] = useState('')
  const [feeding, setFeeding] = useState('')
  const [medication, setMedication] = useState('');
  const [isSucess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [formValid, setFormValid] = useState(true);
  const [reservationVist, setReservationVisit] = useState(false);
  const [departureDateValid, setDepartureDateValid] = useState(true);
  const [petVisit, setPetVisit] = useState(false);
  
  useEffect(() => {
    if (guestIds.length > 0 && reservationType && arrdate && depdate && departureDateValid) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [arrdate, depdate, guestIds])

  // Modal show & Hide
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const handleShow = async (e: any) => {
    if (!formValid) {
      e.preventDefault()
      setLoading(true)
      const checkinDate = new Date(arrdate.getFullYear(), arrdate.getMonth(), arrdate.getDate(), 9, 0, 0);
      let checkoutDate = new Date(depdate.getFullYear(), depdate.getMonth(), depdate.getDate(), 15, 0, 0);
      if(parseInt(reservationType) !== 1)
      {
        const newArrDate = new Date(arrdate.getFullYear(), arrdate.getMonth(), arrdate.getDate())
        const newDepDate = new Date(depdate.getFullYear(), depdate.getMonth(), depdate.getDate())
        const timeDiff = newDepDate.getTime() - newArrDate.getTime()
        if (timeDiff/ (1000 * 60 * 60 * 24) > 0) {
          alert('Please note that Departure Date is set as Arrival Date for this reservation')
        }
        checkoutDate = new Date(arrdate.getFullYear(), arrdate.getMonth(), arrdate.getDate(), 15, 0, 0);;
      }
      
      const body = {
        ServiceClass: parseInt(reservationType),
        GuestIds: guestIds.toString(),
        "ClientId": props.clientId,
        "Checkin": Moment(checkinDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        "Checkout": Moment(checkoutDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        "ReservationNotes": reservation,
        "GroomingNotes": grooming,
        "TrainingNotes": training,
        "FeedingNotes": feeding,
        "MedicationNotes": medication,
      }
      await axios
        .post(`${API_URL}/Reservation`, body, {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then((res) => {
          setLoading(false)
          setIsSuccess(true);
          setMessageTitle('Thank you');
          setMessageBody('Your reservation request was successful!')
          setShow(true)
        })
        .catch((error) => {
          if (error.response){
            console.log('error.response =>', error.response.data)
            const { data } = error.response;
            setMessageBody(data.Message)
            }else if(error.request){
            }else if(error.message){
            }
          setIsError(true);
          setMessageTitle('Sorry, this reservation request is incomplete');
          setShow(true)
          setLoading(false)
        })
    }
  }


  const handleCheckboxChange = (guest: any) => {
    const isChecked = guestIds.some((guestId) => guestId === guest)
    if (isChecked) {
      const filters = guestIds.filter((guestId) => guestId !== guest)
      setGuestIds(filters)
    } else {
      const guestNewId = Object.assign([], guestIds);
      guestNewId.push(guest)
      setGuestIds(guestNewId)
    }
  }

  const resetForm = () => {
    setIsError(false);
    setIsSuccess(false);
    setMessageTitle('');
    setMessageBody('')
    setShow(false)
  }

  const handleArrivalDateValue = (newValue: any) => {
    var resType = ''
    const options = document.getElementById('reservation_type')?.getElementsByTagName('option')
    if(options) {
      _.forEach(options, (option: any) => {
        if(option.selected) resType = option.value
      });
      }
    const newDate = newValue[0]
    setArrDate(newDate)
    const newDepDate = new Date(newDate)
    if(resType === '1'){
      newDepDate.setDate(newDepDate.getDate() + 1)
    }
    setDepDate(newDepDate)
  }

  const handleDepartureDateValue = (newValue: any) => {
    const newDate = new Date(newValue[0].getFullYear(), newValue[0].getMonth(), newValue[0].getDate())
    setDepDate(newDate)
  }

  const handleReservationTypeChange = (e: any) => {
    setDepartureDateValid(true)
    setReservationVisit(true)
    e.target.value === "1" ? setCheckoutState(true) : setCheckoutState(false)
    setReservationtype(e.target.value)
    const newDepDate = new Date(arrdate)
    if(e.target.value === "1") {
      newDepDate.setDate(newDepDate.getDate() + 1)
    }
    setDepDate(newDepDate)
  }

  const handleResetForm = () => {
    setReservationtype('')
    setArrDate(date)
    setDepDate(date)
    setGuestIds([])
    setReservation('')
    setGrooming('')
    setTraining('')
    setFeeding('')
    setMedication('')
    resetForm()
  }

  return (
    <>
      <div className='container'>
        <div className='row'>
          <form onSubmit={handleShow} id='reservation_form'>
            <h3 className='fw-bolder mb-3'>New Reservation - Single Reservation Request</h3>

            <div className='card'>
              <div className='card-body'>
                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label required'>
                    Reservation Type
                  </label>
                  <div className='col-lg-4 col-12'>
                    <select
                      name='reservation_type'
                      id='reservation_type'
                      className='form-select form-select-solid'
                      value={reservationType}
                      onChange={(e) => {handleReservationTypeChange(e)}}
                    >
                      <option value=''>Please Select</option>
                      {props.Permissions?.LodgingRequest === 1 &&
                      <option value='1'>Lodging</option>}
                      {props.Permissions?.DayCareRequest === 1 &&
                      <option value='2'>Day Care</option>}
                      {props.Permissions?.GroomingRequest === 1 &&
                      <option value='3'>Grooming</option>}
                      {props.Permissions?.TrainingRequest === 1 &&
                      <option value='4'>Training</option>}
                      {/* <option value='5'>Training Class</option> */}
                    </select>
                    {reservationVist && reservationType.length === 0 && (
                      <span className='fv-help-block text-danger'>
                        Please select reservation type.
                      </span>
                    )}
                  </div>
                </div>

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label required'>
                    Pets
                  </label>
                  <div
                    className='col-lg-8 col-12'
                    style={{display: 'grid', gridTemplateColumns: 'auto auto'}}
                  >
                    {props.GuestDetails &&
                      props.GuestDetails.map((guest: any, index: any) => {
                        return (
                          <div
                            className='form-check form-check-custom form-check-solid ps-2 my-2'
                            key={guest.Id}
                          >
                            <label
                              className='form-check-label d-flex align-items-center'
                              htmlFor={guest.Id}
                            >
                              <input
                                className='form-check-input me-2'
                                type='checkbox'
                                id={guest.Id}
                                name={guest.Id}
                                value={guest.Name}
                                checked={guestIds.includes(guest.Id)}
                                onChange={() => {
                                  setPetVisit(true)
                                  handleCheckboxChange(guest.Id)
                                }}
                              />
                              {guest.Name}
                            </label>
                          </div>
                        )
                      })}
                  </div>
                  <div className='col-lg-4' />
                  <div className='col-lg-8 col-12'>
                    {petVisit && guestIds.length === 0 && (
                      <span className='fv-help-block text-danger'>
                        Please select at least one pet.
                      </span>
                    )}
                  </div>
                </div>

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label required'>
                    Arrival Date
                  </label>
                  <div className='col-lg-4 col-12'>
                    <Flatpickr className='form-control form-control-solid' value={arrdate} onChange={handleArrivalDateValue} />
                  </div>
                </div>

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label required'>
                    Departure Date
                  </label>
                  <div className='col-lg-4 col-12'>
                    <Flatpickr className='form-control form-control-solid' options={{minDate: new Date(arrdate)}} value={new Date(depdate)} onChange={handleDepartureDateValue} />
                    {!departureDateValid && (
                      <span className='fv-help-block text-danger'> <br/>
                        Please valid departure date later than arrival date.
                      </span>
                    )}

                  </div>
                </div>

                <hr />

                <h3 className='fw-bolder mb-3'>Requests</h3>

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label'>
                    New Feeding Instructions
                  </label>
                  <div className='col-lg-4 col-12'>
                    <textarea
                      rows={4}
                      className='form-control form-control-solid'
                      aria-label='With textarea'
                      style={{resize: "none"}}
                      value={feeding}
                      maxLength={300}
                      onChange={(e) => setFeeding(e.target.value)}
                    ></textarea>
                    <label htmlFor='' className='col-lg-12 col-12 text-right'>
                      characters -&nbsp;
                      {feeding.length}
                      {' '}
                      / 300
                    </label>
                  </div>
                </div>

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label'>
                    New Medication Instructions
                  </label>
                  <div className='col-lg-4 col-12'>
                    <textarea
                      rows={4}
                      className='form-control form-control-solid'
                      aria-label='With textarea'
                      style={{resize: "none"}}
                      value={medication}
                      maxLength={300}
                      onChange={(e) => setMedication(e.target.value)}
                    ></textarea>
                    <label htmlFor='' className='col-lg-12 col-12 text-right'>
                      characters -&nbsp;
                      {medication.length}
                      {' '}
                      / 300
                    </label>
                  </div>
                </div>


               

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label'>
                    Grooming/Bathing Requests
                  </label>
                  <div className='col-lg-4 col-12'>
                    <textarea
                      rows={4}
                      className='form-control form-control-solid'
                      aria-label='With textarea'
                      style={{resize: "none"}}
                      value={grooming}
                      maxLength={300}
                      onChange={(e) => setGrooming(e.target.value)}
                    ></textarea>
                      <label htmlFor='' className='col-lg-12 col-12 text-right'>
                        characters -&nbsp;
                        {grooming.length}
                        {' '}
                        / 300
                      </label>
                  </div>
                </div>

                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label'>
                    Training Requests
                  </label>
                  <div className='col-lg-4 col-12'>
                    <textarea
                      rows={4}
                      className='form-control form-control-solid'
                      aria-label='With textarea'
                      style={{resize: "none"}}
                      value={training}
                      maxLength={300}
                      onChange={(e) => setTraining(e.target.value)}
                    ></textarea>
                    <label htmlFor='' className='col-lg-12 col-12 text-right'>
                      characters -&nbsp;
                      {training.length}
                      {' '}
                      / 300
                    </label>
                  </div>
                </div>
                <div className='row mb-10'>
                  <label htmlFor='' className='col-lg-4 fw-bold text-muted form-label'>
                    Other Special Instructions
                  </label>
                  <div className='col-lg-4 col-12'>
                    <textarea
                      rows={4}
                      className='form-control form-control-solid'
                      aria-label='With textarea'
                      style={{resize: "none"}}
                      value={reservation}
                      maxLength={300}
                      onChange={(e) => setReservation(e.target.value)}
                    ></textarea>
                    <label htmlFor='' className='col-lg-12 col-12 text-right'>
                      characters -&nbsp;
                      {reservation.length}
                      {' '}
                      / 300
                    </label>
                  </div>
                </div>
               

                <div className='row mb-10'>
                  <div className='col-lg-4'></div>
                  <div className='d-flex justify-content-evenly col-lg-4'>
                    <button 
                      type="reset" 
                      className='btn btn-primary'
                      onClick={handleResetForm}
                    >
                      Cancel
                    </button>
                    <button 
                      type='submit'
                      id='send_request'
                      className='btn btn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#requestPopup'
                      disabled={formValid}
                    >
                      <span>Send Request</span>
                      {loading && (
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{messageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{messageBody}</Modal.Body>
        <Modal.Footer>
          {isSucess && (
            <a href='/dashboard' className='btn btn-primary'>
              OK
            </a>
          )}
          {isError && (
            <button 
            onClick={resetForm}
            className='btn btn-primary'>Ok</button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default connector(NewReservation)
