/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { DashboardOverviewModel } from '../../../../app/modules/dashboard/models/DashboardOverviewModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const dashboard: DashboardOverviewModel = useSelector<RootState>(({auth}) => auth.dashboard, shallowEqual) as DashboardOverviewModel
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Home'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/profile/overview'
        icon='/media/icons/duotune/general/gen019.svg'
        title='My Profile'
        fontIcon='bi-layers'
      />
      {dashboard?.Permissions?.ReservationRequests === 1 &&
      <AsideMenuItem
        to='/newreservation'
        icon='/media/icons/duotune/files/fil002.svg'
        title='Reservation Request'
        fontIcon='bi-layers'
      />}
      {dashboard?.Permissions?.ReportCards === 1 &&
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen024.svg'
        title='Report Cards'
        fontIcon='bi-layers'
      />}

    </>
  )
}
