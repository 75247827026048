import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getClientInvitation} from '../redux/AuthCRUD'
import { DashboardOverviewModel } from '../../dashboard/models/DashboardOverviewModel'
import { left } from '@popperjs/core'

const initialValues = {
  Email: '',
  Password: '',
  ConfirmPassword: ''
}

// export function Invite(props: any) {
export function Invite() {
    const {clientId} = useParams()
  const [loading, setLoading] = useState(false)
  var dashboardInit: DashboardOverviewModel={
    Id:1,
    ClientId:1,
    FirstName:'',
    LastName:'',
    Token:'',
    Email:'',
    GuestDetails:[],
    FacilityDetails: undefined,
    ClientDetails:undefined,
    UserName: ''
  };
  const [dashboard, setDashboard] = useState<DashboardOverviewModel>(dashboardInit)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const getInvitation = async(clientId: string | undefined) =>{
    if(clientId) {
      const dashboard = await getClientInvitation(clientId)
      console.log('invite das', dashboard)
      if(dashboard.Error && dashboard.Error !== '') {
        setHasErrors(true)
        setErrorMessage(dashboard.Error)
      }
      console.log(hasErrors, dashboard.Error)
      initialValues.Email = dashboard.Email
      localStorage.Email =  dashboard.UserName && dashboard.UserName !== null && dashboard.UserName !== '' ? dashboard.UserName : dashboard.Email
      localStorage.Token = dashboard.Token
      setDashboard(dashboard)
      }
}

useEffect(()=>{
    getInvitation(clientId)
},[])

   return (
    <>
        <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Welcome to the <br></br> Client Portal </h1>
        {hasErrors === true && (
          <div> 
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                {errorMessage ? errorMessage : "Sorry, looks like there are some errors detected, please try again." }
                </div>


              </div>
             <div>

 <Link to='/auth/login' className='btn btn-lg btn-primary w-100 mb-5'>
            {' '}
            Sign In
          </Link>
          

             </div>
              </div>
            )}
        
        {dashboard && dashboard.FirstName !== '' && (<>
            <h3 className='text-gray-400 fw-bold'>
              {dashboard.FacilityDetails?.Name} <br />

            </h3>
            <br />

            <p className='text-gray-400 fw-bold'>Set up credentials for  </p>

            <h5 className='' style={{ textAlign: left }}>
              {dashboard.ClientDetails?.FirstName} {dashboard.ClientDetails?.LastName} <br />
              {dashboard.ClientDetails?.Street}
              {dashboard.ClientDetails?.City}, {dashboard.ClientDetails?.StateCd} {dashboard.ClientDetails?.PostalCode}
            </h5>
            <br />
            <h5 style={{ textAlign: left }}>Pet(s)</h5>
            <div className='text-gray-400 fw-bold fs-6' style={{ textAlign: left }}>
              {dashboard.GuestDetails?.map((guest: any) => {
                return <li style={{ color: "#000", fontSize: "15px" }} key={guest.Id}>{guest.Name} ({guest.BreedName})</li>

              })}

            </div>
            <br/>
            <br/>
          <div className='text-center'>
              <Link to={`/auth/signup/${clientId}`} className='btn btn-color-gray-600 btn-active-color-primary'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
              >
                {!loading && <span className='indicator-label'>Sign Up</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              </Link>
              <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder mb-5'
            >
              Cancel
            </button>
          </Link>
            </div></>)}
            </div>
    </>)

}
