import {FC, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {RootState} from '../../../../setup'
import { DashboardOverviewModel } from '../models/DashboardOverviewModel'
import { toAbsoluteUrl} from '../../../../_metronic/helpers/index'
import axios from 'axios'
   
const mapState = (state: RootState) => ({
  FirstName: state.auth?.dashboard?.FirstName,
  GuestDetails: state.auth.dashboard?.GuestDetails,
  FacilityDetails: state.auth.dashboard?.FacilityDetails,
  Permissions: state.auth.dashboard?.Permissions
})
const connector = connect(mapState)

const DashboardOverview: FC = (props: any) => {

  const[loading,setLoading] = useState(true)
  useEffect((  )=>{
   
    setTimeout(()=>{setLoading(false)},1000) 
},[])
  const openReservation = () => {
  window.location.href="/newreservation"
}
  return (
    <>{!loading &&
      <div className='card' style={{padding: "20px"}} >
          <h1 style={{color: "#009ef7",fontSize:"24px",paddingBottom:"20px"}}>Welcome, {props?.FirstName}</h1>      
         <div className="card-body pt-2">
              {
                props.GuestDetails && props.GuestDetails.map((guest: any) => {
                  return guest.GuestType === 1 ? 
                  <div className='d-flex align-items-center mb-7' key={`guest_${guest.Id}`}>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-30px me-5'>
                    <img src={toAbsoluteUrl('/media/logos/dog.svg')} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                    {guest.Name}
                    </a>
                    <span className='text-muted d-block fw-bold'>{guest.BreedName}</span>
                  </div>
                  {/* end::Text */}
                </div>
                    : 
                    <div className='d-flex align-items-center mb-7' key={`guest_${guest.Id}`}>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-30px me-5'>
                    <img src={toAbsoluteUrl('/media/logos/cat.svg')} className='' alt='' />
                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                    {guest.Name}
                    </a>
                    <span className='text-muted d-block fw-bold'>{guest.BreedName}</span>
                  </div>
                  {/* end::Text */}
                </div>
                  
                })
              }


       
         </div>
         
       
      {props.Permissions?.ReservationRequests === 1 &&
      <div className='card' style={{padding: "20px"}} >

         <div className='d-flex justify-content-center py-6 px-9'>
        <button type='submit' onClick={openReservation} className='btn btn-primary'>New Request</button>
        </div>
      <h4 className='text-center'> Make a New Reservation Request</h4>
      </div>}
</div>
}
</>
  )
}

export default connector(DashboardOverview);