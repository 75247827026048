import React, {FC} from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { DashboardOverviewModel } from '../../../../app/modules/dashboard/models/DashboardOverviewModel'
import { RootState } from '../../../../setup'
import {MenuInner} from './MenuInner'
const mapState = (state: RootState) => ({
  
  FirstName: state.auth?.dashboard?.FirstName,
  GuestDetails: state.auth.dashboard?.GuestDetails,
  FacilityDetails: state.auth.dashboard?.FacilityDetails})
const connector = connect(mapState)
const Header: FC = (props: any) => {
  const dashboard: DashboardOverviewModel = useSelector<RootState>(({auth}) => auth.dashboard, shallowEqual) as DashboardOverviewModel
  return (
<div>
 <h1 style={{color: "#009ef7",fontSize:"14px",paddingTop:"20px"}}>{dashboard?.FacilityDetails?.Name}</h1> 
<div
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle=''
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      
      <div
        className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
     
        <MenuInner />
      </div>
    </div> 
</div>

  
  )
}

export default connector(Header)
