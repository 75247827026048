/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {getUserDashboardById, create, login} from '../redux/AuthCRUD'

const loginSchema = Yup.object().shape({
  Email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('UserName is required'),
  Password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(20, 'Maximum 50 symbols')
    .required('Password is required'),
  ConfirmPassword: Yup.string()
  .min(8, 'Minimum 8 symbols')
  .max(20, 'Maximum 50 symbols')
  .required('Password Again is required'),
})

const initialValues = {
  Email: '',
  Password: '',
  ConfirmPassword: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SignUp(props:any) {
  const {clientId} = useParams()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const email = localStorage.Email
  initialValues.Email = email
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if(values.Password !== values.ConfirmPassword) {
        setHasErrors(true)
        setErrorMessage('Paswords does not match');
      } else {
      setLoading(true)
      setTimeout(() => {
        if(clientId) {
          create(values.Email, values.Password, localStorage.Token, clientId)
          .then(async ({data}) => {
            setLoading(false)
            login(values.Email, values.Password)
              .then(async ({data}) => {
                setLoading(false)
                var accessToken =data.Token;
                dispatch(auth.actions.login(accessToken))
                var clientUser = {
                  Id: data.Id,
                  ClientId: data.ClientId,
                  FirstName: data.FirstName,
                  LastName: data.LastName,
                  Email: data.Email,
                  UserName: data.UserName
                }
                dispatch(auth.actions.setClientUser(clientUser))
                const dashboard = await getUserDashboardById(data)
                dispatch(auth.actions.fulfilldashboard(dashboard))
              })
              .catch(() => {
                setLoading(false)
                setSubmitting(false)
                setStatus('The login detail is incorrect')
              })
          })
          .catch((err) => {
            setErrorMessage(err?.response?.data?.Message)
            setLoading(false)
            setSubmitting(false)
            setHasErrors(true)
            setStatus('The login detail is incorrect')
          })
        }
      }, 1000)
    }
    }
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign Up</h1>
        
      </div>
      {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
            {errorMessage ? errorMessage : "Sorry, looks like there are some errors detected, please try again." }
            </div>
          </div>
        )}

      {/* begin::Heading */}
      <p className='text-gray-400 fw-bold'>Please set your username. It can be your email or another unique name.  </p>
   

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>UserName</label>
        <input
          placeholder='UserName'
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.Email && formik.errors.Email},
            {
              'is-valid': formik.touched.Email && !formik.errors.Email,
            }
          )}
          {...formik.getFieldProps('Email')}
          type='text'
          name='Email'
          autoComplete='off'
        />
        {formik.touched.Email && formik.errors.Email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.Email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <p className='text-gray-400 fw-bold'>Set a password. It should be 8-20 characters long and requires numbers, captial and small letters.</p>
<br></br>   
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('Password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.Password && formik.errors.Password,
            },
            {
              'is-valid': formik.touched.Password && !formik.errors.Password,
            }
          )}
        />
        {formik.touched.Password && formik.errors.Password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.Password}</span>
            </div>
          </div>
        )}
        </div>
    
      <div className='fv-row mb-10'>          
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password Again</label>
            {/* end::Label */}
            
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('ConfirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.ConfirmPassword && formik.errors.ConfirmPassword,
            },
            {
              'is-valid': formik.touched.ConfirmPassword && !formik.errors.ConfirmPassword,
            }
          )}
        />
        {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.ConfirmPassword}</span>
            </div>
          </div>
        )}
      </div>
    
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          // disabled={formik.isSubmitting || !formik.isValid}
          onClick={props.updateInvite}

        >
          {!loading && <span className='indicator-label'>Create Account</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>


      </div>
      {/* end::Action */}
    </form>
  )
}
