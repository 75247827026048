import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {call, put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {DashboardOverviewModel} from '../../dashboard/models/DashboardOverviewModel'
import {getClientInvitation, getClientProfile, getUserByToken} from './AuthCRUD'
import {getUserDashboardById} from './AuthCRUD'
import {ClientProfileModel} from '../../accounts/models/ClientProfileModel'
import { ClientUserModel } from '../models/ClientUserModel'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  SetClientUser: '[Load ClientUser] Auth API',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  DashboardRequested: '[Request Dashboard] Action',
  DashboardLoaded: '[Load Dashboard] Auth API',
  SetDashboard: '[Set Dashboard] Action',
  ClientRequested: '[Request Client] Action',
  ClientLoaded: '[Load Client] Auth API',
  SetClient: '[Set Client] Action',
  ClientInvitation: '[Client Invitation] Action',

}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  dashboard: undefined,
  client:undefined,

}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  dashboard?: DashboardOverviewModel
  clientUser?: ClientUserModel
  client?:ClientProfileModel
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken','dashboard', 'clientUser']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined,dashboard:undefined,client:undefined, clientUser:undefined}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }
     
      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }
      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }
      case actionTypes.DashboardRequested: {
        return {...state, dashboard: undefined}
      }
      case actionTypes.DashboardLoaded: {
        const dashboard = action.payload?.dashboard
        return {...state, dashboard}
      }
      case actionTypes.SetClientUser: {
        const clientUser = action.payload?.clientUser
        return {...state, clientUser}
      }
      case actionTypes.SetDashboard: {
        const dashboard = action.payload?.dashboard
        return {...state, dashboard}
      }
      case actionTypes.ClientRequested: {
        return {...state, client: undefined}
      }
      case actionTypes.ClientLoaded: {
        const client = action.payload?.client
        return {...state, client}
      }

      case actionTypes.SetClient: {
        const client = action.payload?.client
        return {...state, client}
      }

      case actionTypes.ClientInvitation: {
        const client = action.payload?.client
        return {...state, client}
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => 
  { 
    return({type: actionTypes.Login, payload: {accessToken}})},
  register: (Token: string) => ({
    type: actionTypes.Register,
    payload: {Token},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  requestDashboard: () => ({
    type: actionTypes.DashboardRequested,
  }),
  requestClient: () => ({
    type: actionTypes.ClientRequested,
  }),
  setClientUser: (clientUser: ClientUserModel) => ({type: actionTypes.SetClientUser, payload: {clientUser}}),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  fulfilldashboard: (dashboard: DashboardOverviewModel) => ({type: actionTypes.DashboardLoaded, payload: {dashboard}}),
  setdashboard: (dashboard: DashboardOverviewModel) => ({type: actionTypes.SetDashboard, payload: {dashboard}}),
  fulfillClient: (client: ClientProfileModel) => ({type: actionTypes.ClientLoaded, payload: {client}}),
  setClient: (client: ClientProfileModel) => ({type: actionTypes.SetClient, payload: {client}})
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested,  function* userRequested() {
    const user : UserModel = yield call(() => getUserByToken(''));

    yield put(actions.fulfillUser(user))
  })
  yield takeLatest(actionTypes.DashboardRequested,  function* dashboardRequested() {
    const dashboard : DashboardOverviewModel = yield call(() => getUserDashboardById(undefined));

    yield put(actions.fulfilldashboard( dashboard))
  })

  yield takeLatest(actionTypes.ClientInvitation,  function* invitationRequested() {
    console.log('Are we getting here?')
    const dashboard : DashboardOverviewModel = yield call(()=>getClientInvitation("3D15CBAC-9586-4046-9A58-B473A1E85591"));

    yield put(actions.fulfilldashboard( dashboard))
  })

  yield takeLatest(actionTypes.ClientRequested, function* clientRequested() {
    const client =  getClientProfile('3D15CBAC-9586-4046-9A58-B473A1E85591')
    yield put(actions.fulfillClient(client))
  })
}